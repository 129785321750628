import * as React from "react";
import styled from "styled-components";

// so we can do flexbox / split panels with all the space
export const PortalContainer = styled.div`
  height: 100vh;
  width: 100vw;
  background-color: lightblue;
  user-select: none;
  pointer-events: none;
  position: absolute;
  top: 0px;
  left: 0px;
  overflow: hidden;
  opacity: .1;
`;

export const FullViewPort = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

export const DevNav = styled.div`
  border: 2px solid grey;
  flex: 0;
  padding: 5px;
`;

export const DevViews = styled.div`
  border: 2px solid blue;
  flex: 1;
  padding: 5px;
  display: flex;
  overflow: hidden;
`;

export const MainContent = styled.div`
  display: flex;
  flex: 1;
  max-width: 100%;
`;

export const ExperimentPanels = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  max-width: 100%;
`;

export const EntryListScroll = styled.div`
  flex: 3;
  overflow-y: scroll;
  border: 1px solid black;
  display: flex;
`;

export const WritingPanel = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 20px;
  margin: 20px;
  border: 1px solid black;
`;

export const WritingPrompt = styled.div`
  text-align: center;
  text-justify: center;
`;

export const PanelsOuter = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
`;

export const Panel = styled.div`
  flex: 1;
  border: 1px solid black;
  display: flex;
  flex-direction: column;
`;

export const Instructions = styled.h1`
  height: 100px;
  font-size: 30px;
  padding: 30px;
  flex: 0;
`;

export const TextEditorOuter = styled.div`
  flex: 1;
  display: flex;
  overflow: auto;
`;

export const PdfPageOuter = styled.div`
  position: relative;
  border-bottom: 1px solid lightgrey;
`;

export const NavBar = styled.div`
  font-size: 30px;
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  flex-flow: row;
  flex: 0;
  margin: 1px;
  z-index: 4;
`;

export const ViewPortContainer = styled.div`
  --border-size: 1;
  max-height: 100vh;
  border: var(--border-size) solid lightgrey;
  border-radius: 5px;
  font-size: 30px;
  overflow: s;
  flex-flow: column;
  display: flex;
`;

export const MainContainer = styled.div`
  flex: 1 2;
  background-color: white;
  overflow: none;
  position: relative;
  display: flex;
  margin: 15px;
`;
