import * as React from "react";
import styled from "styled-components";

export const Avatar = styled.div`
  width: 50vw;
  height: 50vw;
  max-height: 300px;
  max-width: 300px;
  border-radius: 50%;
  background-color: black;
`;

export const ResponsiveLayout = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
`;

const Github = styled.div`
  flex: 1;
`;

const TopLevelText = styled.div`
  flex: 0;
  border: 1px solid black;
`;

export const Entry = () => {
  return (
    <ResponsiveLayout>
      <Avatar></Avatar>
      <TopLevelText>some text</TopLevelText>
    </ResponsiveLayout>
  );
};
