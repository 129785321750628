import * as React from "react";
import { HashRouter, Route, Switch, Link } from "react-router-dom";
import { FullViewPort, DevNav } from "./StyledComponents";
import { Entry } from "./Entry";
import { PDFViewer } from "@react-pdf/renderer";
import { Cv } from "./Cv2pdf";
const CVViewer = () => (
  <PDFViewer style={{flex: 1}}>
    <Cv></Cv>
  </PDFViewer>
);

const todo = () => {
  return <div>
    <div>cv</div>
    <div>demos</div>
    <div>blog</div>
  </div>
}
const linkRoute = [
  { to: "/Entry", label: "Entry", component: Entry },
  { to: "/cv", label: "cv", component: CVViewer },
  { to: "/", label: "todo", component: todo },
];
const showNav = false;

export default () => {
  return (
    <HashRouter>
      <FullViewPort id="FullViewPort">
        {showNav && (
          <DevNav id="DevNav">
            {linkRoute.map(lr => {
              return (
                <Link key={lr.label} to={lr.to} style={{ margin: 5 }}>
                  {lr.label}
                </Link>
              );
            })}
          </DevNav>
        )}
        <Switch>
          {linkRoute.map(lr => {
            return (
              <Route
                key={lr.label}
                exact
                path={lr.to}
                component={lr.component}
              />
            );
          })}
          <Route component={() => <h1>No Content</h1>} />
        </Switch>
      </FullViewPort>
    </HashRouter>
  );
};
