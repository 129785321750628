import * as React from "react";
import { render } from "react-dom";
import Router from "./router";

class App extends React.Component {
  render() {
    return (
        <Router />
    );
  }
}

render(<App />, document.getElementById("root"));
