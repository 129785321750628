//@ts-nocheck
import * as React from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Font,
  Image,
  Link
} from "@react-pdf/renderer";
import font from "./fonts/Roboto-Regular.ttf";
import fontBold from "./fonts/Roboto-Bold.ttf";
import fontItalic from "./fonts/Roboto-Italic.ttf";
import githubImg from "./images/GitHub-Mark-64px.png";
import gmailIcon from "./images/gmail.png";
import phoneIcon from "./images/phone.png";
// Create styles
Font.register({
  family: "Roboto",
  src: font,
  fontStyle: "normal",
  fontWeight: "normal",
  format: "truetype"
});
Font.register({
  family: "Roboto",
  src: fontBold,
  fontStyle: "normal",
  fontWeight: "bold",
  format: "truetype"
});
Font.register({
  family: "Roboto",
  src: fontItalic,
  fontStyle: "italic",
  fontWeight: "normal",
  format: "truetype"
});

const colors = {
  darkGrey1: "#737373",
  blue1: "#3886d4",
  sectionHeader: "black"
};

const styles = StyleSheet.create({
  page: {
    paddingHorizontal: 30,
    paddingVertical: 22,
    fontFamily: "Roboto"
  },
  section: {}
});

const SectionHeader = ({ children }) => {
  return (
    <View
      style={{
        //   backgroundColor: "lightgrey",
        display: "flex",
        flexDirection: "row",
        alignItems: "center"
      }}
    >
      <Text
        style={{
          fontSize: 33,
          fontWeight: "bold",
          color: colors.sectionHeader
        }}
      >
        {children}
      </Text>
      <View
        style={{
          backgroundColor: colors.sectionHeader,
          flex: 1,
          height: 10,
          marginLeft: 25
        }}
      ></View>
    </View>
  );
};
const iconTextStyle = {
  color: "black",
  fontWeight: "bold",
  marginTop: -3
};

const GithubIcon = () => (
  <Image src={githubImg} style={{ height: 33, width: 33, padding: 5 }}></Image>
);
const GmailIcon = () => (
  <Image
    src={gmailIcon}
    style={{ height: 34, width: 30, padding: 5, paddingLeft: 0 }}
  ></Image>
);
const PhoneIcon = () => (
  <Image
    src={phoneIcon}
    style={{ height: 32, width: 18, padding: 5, paddingLeft: 0 }}
  ></Image>
);
const GitHubLink = () => (
  <Link src="https://github.com/matt-erhart" style={iconTextStyle}>
    matt-erhart
  </Link>
);
const GmailLink = () => (
  <Text style={{ ...iconTextStyle, marginTop: 0 }}>mattjerhart@gmail.com</Text>
);
const PhoneText = () => (
  <Text style={{ ...iconTextStyle, marginTop: 2 }}>858-652-0771</Text>
);

const Github = () => {
  return (
    <View
      style={{ display: "flex", flexDirection: "row", alignItems: "center" }}
    >
      <GithubIcon />
      <GitHubLink></GitHubLink>
    </View>
  );
};

const Gmail = () => {
  return (
    <View
      style={{ display: "flex", flexDirection: "row", alignItems: "center" }}
    >
      <GmailIcon></GmailIcon>
      <GmailLink></GmailLink>
    </View>
  );
};

const Phone = () => {
  return (
    <View
      style={{ display: "flex", flexDirection: "row", alignItems: "center" }}
    >
      <PhoneIcon></PhoneIcon>
      <PhoneText></PhoneText>
    </View>
  );
};

const ContactInfo = () => {
  return (
    <View
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between"
      }}
    >
      <Phone />
      <Gmail />
      <Github />
    </View>
  );
};
const Python = () => <Text style={{ fontWeight: "bold" }}>Python</Text>;
const Reactjs = () => <Text style={{ fontWeight: "bold" }}>React</Text>;
const Webgl = () => <Text style={{ fontWeight: "bold" }}>Webgl</Text>;
const D3 = () => <Text style={{ fontWeight: "bold" }}>D3</Text>;

// const sometext = () => (<Text style={{ fontSize: 15, backgroundColor: "lightgrey" }}>
// Visualized knowledge graphs created while readers annotate pdfs using{" "}
// <Reactjs />
// </Text>
// <Text style={{ fontSize: 15, backgroundColor: "lightgrey" }}>
// Visualized the temporal dynamics of worm neurons & human brains using{" "}
// <Webgl /> and <Reactjs />
// </Text>
// <Text style={{ fontSize: 15, backgroundColor: "lightgrey" }}>
// Visualized, analyzed, and collected data from neuroimaging research
// for 6 years using matlab and <Python></Python>.
// </Text>)
// Create Document Component
const DataVisSummary = () => <Text>
  4 years of frontend engineering experience. 
  10 years of programming, data analysis, visualization, and research experience. 

</Text>


const Summary = () => {
  return (
    <View>
      <Text style={{ fontSize: 10, fontStyle: "italic", padding: 5 }}>
      <DataVisSummary></DataVisSummary>
      </Text>
    </View>
  );
};

const conductiv = {
  dates: "3/20 - Present (Lead since 3/21)",
  duration: "1+ years",
  title: `Lead Full-Stack Staff Engineer`,
  employer: "Conductiv. Remote Full-Time",
  accomplishments: [
    `Worked closely with internal users and product managers to set and achieve goals`,
    `Led a small team of full-stack engineers and data scientists to build data centric products`,
    `Reduced data ingest time from days to minutes using Python, SQL, and Azure Services`,
    `Built front-end React components with Typescript`,
    `Fixed, maintained, researched, and improved systems built with C#, SQL, and Azure cloud`
  ]
};

const UMD = {
  dates: "2/18 - 6/20",
  duration: "2+ years",
  title: `Full-Stack Developer / Design Researcher`,
  employer: "University of Maryland and Carnegie Mellon University. Remote Part-Time",
  accomplishments: [
    `Built HCI research prototypes with Typescript, React, Redux, Firebase, Electron, D3, and Python`,
    `Created a custom pdf annotation tool with pdf.js and React`,
    `Visualized connections between annotations in scientific publications using editable node-link diagrams`,
    `Created theories, UI, and simulations to show optimization algorithms can inform human design practices`,
    `Converted the team's ideas and goals into specific designs and deployable code`,
    `Turned literature review into theories and ideas that drove the core system designs`
  ]
};

const contracts = {
  dates: "3/17 - 11/17",
  duration: "8 months",
  title: `Data Visualization & Front-End Engineering Freelancer`,
  employer:
    "Carnegie Mellon University. UC, San Diego. Mari Inc. Remote short-term contracts",
  accomplishments: [
    `Visualized connections between concepts and researchers at CMU using D3 and React`,
    `Built a custom flow chart creation tool and a custom Chrome extension for note taking`,
    `Converted designer sketches and verbal descriptions into performant visualizations`,
    `Visualized worm neuron interactions using 3d animations built with React and Webgl`,
    `Wrote a custom webgl shader to animate a large number of elements at 60 fps`,
    `Built React components for a web platform that tracks and visualizes student skills`
  ]
};

const CMU = {
  dates: "3/17 - 9/17",
  duration: "6 months",
  title: `Data Visualization Developer`,
  employer:
    "HCI Institute at Carnegie Mellon University. Remote short-term contract",
  accomplishments: [
    `Visualized connections between concepts and researchers at CMU using D3 and React`,
    `Built a custom flow chart creation tool and a custom Chrome extension for note taking`
  ]
};

const DesignLab = {
  dates: "1/16 - 9/16",
  duration: "9 months",
  title: `PhD Student Researcher / Staff Research Associate 3`,
  employer: "Design Lab at UC, San Diego",
  accomplishments: [
    "Designed an experiment grounded in the science of team creativity, diversity, and performance",
    "Built an online experiment using AngularJS and deployed it to Mechanical Turk",
    `Visualized time changing brain activations on 3d brain surfaces with Three.js and AngularJS`,
    `Presented a design for a EEG and eye-tracking text input method at the Pervasive Health conference`,
  ]
};

const alphabet = {
  dates: "8/14 - 8/15",
  duration: "1 year",
  title: `PhD Student Researcher`,
  employer: "Rayner Eyetracking Lab at UC, San Diego",
  accomplishments: [
    `Improved parafoveal letter recognition by 20% using an alphabet redesigned with color`,
    `Optimized the colored alphabet's design with behavioral data and a custom algorithm`,
    `Led every aspect of the research process, from concept to presentation of results`,
  ]
};

const phdStudentResearcher = {
  dates: "8/14 - 9/16",
  duration: "2 years",
  title: `PhD Student Researcher / Staff Research Associate 3`,
  employer: "Rayner Eyetracking Lab & Design Lab at UC, San Diego",
  accomplishments: [
    `Improved parafoveal letter recognition by 20% using an alphabet redesigned with color`,
    `Optimized the colored alphabet's design with behavioral data and a custom algorithm`,
    `Led every aspect of the research process, from concept to presentation of results`,
    "Designed an experiment grounded in the science of team creativity, diversity, and performance",
    "Built an online experiment using AngularJS and deployed it to Mechanical Turk",
    `Visualized time changing brain activations on 3d brain surfaces with Three.js and AngularJS`,
    `Presented a design for a EEG and eye-tracking text input method at the Pervasive Health conference`,
  ]
}

const MMIL = {
  dates: "12/08 - 8/14",
  duration: "6 years",
  title: `Staff Research Associate 2`,
  employer: "Multimodal Imaging Lab at UC, San Diego",
  accomplishments: [
    "Oversaw collection of large, 3d, time series neuroimaging data-sets (fMRI, MEG, EEG)",
    "Organized, analyzed, and visualized human performance and dynamic, 3d neuroimaging data",
    "Programmed with Matlab, R, Python, and Bash on Linux based compute clusters",
    `Designed and programmed tasks for neuroscience experiments`,
    `Coauthored 10 peer-reviewed publications and 10 conference posters`,
    `Presented 4 conference posters as first author`
  ]
};

const ExperienceEntry = (props = { json: UMD }) => {
  const { duration, dates, title, employer, accomplishments } = props.json;
  return (
    <View style={{ margin: 3, fontSize: 15 }}>
      <View
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between"
        }}
      >
        <Text style={{ fontWeight: "bold" }}> {`${title}`}</Text>
        <Text style={{ fontWeight: "bold" }}> {`${duration}`}</Text>
      </View>
      <Text style={{ fontSize: 10, marginBottom: 4 }}>
        {`${employer}. ${dates}`}
      </Text>
      {accomplishments.map(a => {
        return (
          <Text style={{ fontSize: 11, paddingLeft: 3 }}>{` - ${a}`}</Text>
        );
      })}
    </View>
  );
};


const Education = () => {
  return (
    <View
    style={{
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      fontSize: 13
    }}
  >
    <View>
      <Text>Language Disorders (incomplete) PhD</Text>
      <Text>Cognitive Neuroscience BS</Text>

    </View>
    <View>
      <Text>UCSD & SDSU</Text>
      <Text>UCSD</Text>
    </View>

    <View>
      <Text>GPA: 3.76</Text>
      <Text>GPA: 3.55</Text>
    </View>
    <View>
      <Text>2014 - 2016</Text>
      <Text>2005 - 2008</Text>
    </View>
  </View>)
};


export const Cv = () => (
  <Document>
    <Page size="A4" style={styles.page}>
      <SectionHeader>Matt Erhart</SectionHeader>
      <ContactInfo />
      <Summary />
      <SectionHeader>Experience</SectionHeader>
      <ExperienceEntry json={conductiv} />
      <ExperienceEntry json={UMD} />
      <ExperienceEntry json={contracts} />
      {/* <ExperienceEntry json={CMU} /> */}
      <ExperienceEntry json={phdStudentResearcher} />
      {/* <ExperienceEntry json={alphabet} /> */}
      <ExperienceEntry json={MMIL} />
      <SectionHeader>Education</SectionHeader>
      <Education></Education>
    </Page>
  </Document>
);